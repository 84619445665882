const SDKInitializationValidationErrors = {
    PAYMENT_SUCCESS_CALLBACK_MISSING: 'onPaymentSuccess callback is required!',
    PAYMENT_FAILURE_CALLBACK_MISSING: 'onPaymentFailure callback is required!',
    ERROR_CALLBACK_MISSING: 'onError callback is required',
};

const CardValidationErrors = {
    INVALID_CARD_NUMBER: 'invalid card number',
    INVALID_CARD_HOLDER: 'invalid card holder name',
    INVALID_CARD_EXPIRY: 'invalid card expiry time',
    INVALID_CARD_CVV: 'invalid card cvv',
};

const DOMErrors = {
    Card: {
        CARD_NUMBER_ELEMENT_NOT_FOUND: 'no element found with attribute data-card-number',
        CARD_CVV_ELEMENT_NOT_FOUND: 'no element found with attribute data-card-cvv',
        CARD_HOLDER_ELEMENT_NOT_FOUND: 'no element found with attribute data-card-holder',
        CARD_EXPIRY_MONTH_ELEMENT_NOT_FOUND: 'no element found with attribute data-card-expiry-mm',
        CARD_EXPIRY_YEAR_ELEMENT_NOT_FOUND: 'no element found with attribute data-card-expiry-yy',
    },
    Emi: {
        CARD_NUMBER_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-number',
        CARD_CVV_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-cvv',
        CARD_HOLDER_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-holder',
        CARD_EXPIRY_MONTH_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-expiry-month',
        CARD_EXPIRY_YEAR_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-expiry-year',
        CARD_BANK_NAME_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-bank',
        CARD_EMI_TENURE_ELEMENT_NOT_FOUND: 'no element found with attribute data-emi-tenure',
    },
    APP_PHONE_NUMBER_ELEMENT_NOT_FOUND: 'no element found with attribute data-app-phone',
    APP_NAME_ELEMENT_NOT_FOUND: 'no element found with attribute data-app-name',

    NETBANKING_BANK_CODE_ELEMENT_NOT_FOUND: 'no element found with attribute data-netbanking-code',
    NETBANKING_IFSC_CODE_ELEMENT_NOT_FOUND: 'no element found with attribute data-netbanking-ifsc',
    NETBANKING_ACCOUNT_NUMBER_ELEMENT_NOT_FOUND: 'no element found with attribute data-netbanking-account',

    UPI_COLLECT_VPA_ELEMENT_NOT_FOUND: 'no element found with attribute data-upi-id',

    UPI_INTENT_PROVIDER_ELEMENT_NOT_FOUND: 'no element found with attribute data-upi-provider',
};

const INVALID_PAYMENT_REQUEST = 'invalid payment request';

export { SDKInitializationValidationErrors, CardValidationErrors, DOMErrors, INVALID_PAYMENT_REQUEST };
